<template>
  <div
    class="banner-container bg-slate-900 py-[16px] px-[16px] md:py-[18px] md:px-[40px] w-full rounded-lg flex flex-col md:flex-row my-6"
    :class="{ 'small': small }"
  >
    <p v-if="!small" class="text-base md:text-2xl relative md:top-[4px] leading-[46px] align-middle flex w-full justify-center mx-auto md:mx-0 md:w-fit">
      No crypto? No worries!
    </p>
    <button
      v-else
      class="flex items-center bg-slate-700 rounded-lg w-full relative justify-center p-2"
      size="2xs"
      @click.prevent.stop="handleBuyCrypto"
    >
      Deposit by Card
    </button>
    <div class="icons-container ml-auto my-auto flex w-full justify-center md:w-fit md:mx-0 md:ml-auto">
      <SvgIcon
        icon="mastercard"
        height="40"
        width="40"
        class="icon mx-[15px] overflow-hidden"
      />
      <SvgIcon
        icon="visa"
        height="40"
        width="60"
        class="icon mx-[15px] overflow-hidden"
      />
      <SvgIcon
        icon="applePay"
        height="40"
        width="60"
        class="icon mx-[15px] overflow-hidden"
      />
      <SvgIcon
        icon="googlePay"
        height="40"
        width="120"
        class="icon mx-[15px] overflow-hidden"
      />
    </div>
  </div>
</template>
<script setup>
import { useUiStore } from '@/store/ui';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

const props = defineProps({
  small: {
    type: Boolean,
    required: false,
  },
  isEmit: {
    type: Boolean,
    required: false,
  },
});

const emits = defineEmits(['select',]);

const uiStore = useUiStore();
const { openTxModal, } = uiStore;

function handleBuyCrypto() {
  if (props.isEmit) {
    emits('select');
  } else {
    openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[2]);
  }
}
</script>
<style lang="scss" scoped>
.banner-container {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;

  &.small {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1em;
    margin: 0 0 0.5em 0;

    > * {
      width: 100%;
    }

    button {
      margin-bottom: 0.5em;

      &:hover {
        background: #5F677C;
      }
    }

    .icon {
      margin: 0 0.5em;
    }
  }
}

</style>
